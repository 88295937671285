export const navigationItemsNew = [
  {
    id: 1,
    title: 'Читать',
    href: '/read/articles',
    nested: [
      { id: 1, title: 'Статьи', href: '/read/articles' },
      { id: 8, title: 'Памятники', href: '/read/monuments' },
      // { id: 7, title: 'Самоизданные статьи', href: '/read/samizdat' },
      { id: 2, title: 'События', href: '/read/news' },
      { id: 3, title: 'Биографии', href: '/read/biographies' },
      { id: 9, title: 'Научные статьи', href: '/read/science' },
      { id: 4, title: 'Документы', href: '/read/documents' },
      // { id: 5, title: 'Курсы', href: '/read/courses' },
      // { id: 6, title: 'Авторы', href: '/authors' },
    ],
    randomItem: null,
    icon: 'book',
  },
  {
    id: 2,
    title: 'Смотреть',
    href: '/watch/lectures',
    nested: [
      { id: 1, title: 'Видеолекции', href: '/watch/lectures' },
      { id: 2, title: 'Видеокурсы', href: '/watch/courses' },
      { id: 3, title: 'Фильмы', href: '/watch/films' },
      { id: 4, title: 'Памятные даты', href: '/watch/memdates' },
    ],
    randomItem: null,
    icon: 'film',
  },
  {
    id: 3,
    title: 'Слушать',
    href: '/listen/lectures',
    nested: [
      { id: 1, title: 'Аудиолекции', href: '/listen/lectures' },
      { id: 2, title: 'Подкасты', href: '/listen/courses' },
      // { id: 3, title: 'Подкасты', href: '/listen/podcasts' },
    ],
    randomItem: null,
    icon: 'headphones',
  },
  {
    id: 15,
    title: 'Россия–моя история',
    href: '/myhistory',
    nested: null,
    randomItem: null,
    icon: 'location',
    isDev: true,
  },
  // {
  //   id: 4,
  //   title: 'Подборки',
  //   href: '/collections',
  //   nested: null,
  //   randomItem: null,
  //   icon: 'layout',
  // },
  {
    id: 5,
    title: 'Лента времени',
    href: '/timeline',
    nested: null,
    randomItem: null,
    icon: 'clock',
  },
  {
    id: 14,
    title: 'Библиотека историка',
    href: '/library',
    nested: null,
    randomItem: null,
    icon: 'library',
    isDev: true,
  },
  // {
  //   id: 6,
  //   title: 'Тесты',
  //   href: '/tests',
  //   nested: null,
  //   randomItem: null,
  //   icon: 'edit',
  // },
  // {
  //   id: 8,
  //   title: 'Афиша',
  //   href: '/poster',
  //   nested: null,
  //   randomItem: null,
  //   icon: 'tv',
  // },
  // {
  //   id: 9,
  //   title: 'Спецпроекты',
  //   href: '/projects',
  //   nested: null,
  //   randomItem: null,
  //   icon: 'pocket',
  // },
  // {
  //   id: 10,
  //   title: 'Мне повезет',
  //   href: this.randomLink,
  //   nested: null,
  //   randomItem: null,
  //   icon: 'random',
  // },
  {
    id: 13,
    title: 'Зима – 2024',
    href: '/summer',
    nested: null,
    randomItem: null,
    icon: 'snowflake',
  },
  // {
  //   id: 11,
  //   title: 'Ко Дню Победы',
  //   href: '/victory-day',
  //   nested: null,
  //   randomItem: null,
  //   icon: 'star-victory',
  // },
  // {
  //   id: 12,
  //   title: 'Исторический интеллект',
  //   href: '/gpt-chat',
  //   nested: null,
  //   randomItem: null,
  //   icon: 'chat-bot',
  // },

  /**
   *
   *
   *
   *
   * Всегда последний !!!!!!! https://meliagr.bitrix24.ru/company/personal/user/343/tasks/task/view/39745/
   */
  {
    id: 7,
    title: 'Учителю и ученику',
    href: '/teacher',
    nested: null,
    randomItem: null,
    icon: 'book-open',
  },
];
